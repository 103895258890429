import "../../CSS/main.css";
import "../../CSS/main_mobile.css";
const MainS1 = () => {
  return (
    <div className="main_s1">
      <img src="/images/main/s1_bg.jpg" alt="" />
    </div>
  );
};

export default MainS1;
