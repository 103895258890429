import "../../CSS/signForm.css";
import "../../CSS/signForm_mobile.css";

const SignFormContent = () => {
  return (
    <div className="signForm_s1">
      <div className="section_container">
        <div className="article_container">
          <div className="title_box">
            <img src="/images/login/back_btn.png" alt="" />
            <p>회원가입</p>
          </div>
          <div className="input_container">
            <div className="input">
              <p>
                아이디<span>이메일 형식으로 가입하세요.</span>
              </p>
              <input />
            </div>
            <div className="input">
              <p>
                비밀번호<span>8글자 이상으로 입력하세요.</span>
              </p>
              <input />
            </div>
            <div className="input">
              <p>비밀번호 확인</p>
              <input />
            </div>
            <div className="input">
              <p>이름</p>
              <input />
            </div>
            <div className="input">
              <p>핸드폰</p>
              <input />
            </div>
            <div className="input input_address">
              <p>주소</p>
              <div className="btn_box">
                <input />
                <div className="address_btn">주소 검색</div>
              </div>
            </div>
            <div className="input">
              <p>상세주소</p>
              <input />
            </div>
            <div className="submit_btn">완료</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignFormContent;
