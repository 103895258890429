import PayS1 from "./payS1";
import PayS2 from "./payS2";
import PayS3 from "./payS3";
const PayContents = () => {
  return (
    <>
      <PayS1 />
      <PayS2 />
      <PayS3 />
    </>
  );
};

export default PayContents;
