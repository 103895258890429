import MypageS1 from "./mypageS1";
import MypageS2 from "./mypageS2";
import MypageS3 from "./mypageS3";
import MypageS4 from "./mypageS4";
const MypageContents = () => {
  return (
    <>
      <MypageS1 />
      <MypageS2 />
      <MypageS3 />
      <MypageS4 />
    </>
  );
};

export default MypageContents;
