import AboutS1 from "./aboutS1";
import AboutS2 from "./aboutS2";
import AboutS3 from "./aboutS3";
import AboutS4 from "./aboutS4";
import AboutS5 from "./aboutS5";
const AboutContents = () => {
  return (
    <>
      <AboutS1 />
      <AboutS2 />
      <AboutS3 />
      <AboutS4 />
      <AboutS5 />
    </>
  );
};

export default AboutContents;
