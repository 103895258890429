import CartS1 from "./cartS1";
import CartS2 from "./cartS2";
const CartContents = () => {
  return (
    <>
      <CartS1 />
      <CartS2 />
    </>
  );
};

export default CartContents;
